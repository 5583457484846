<template>
  <StripeOverlay />
  <TokenModal ref="tokenModal" />
  
  <CustomMatchup @fetch-strategy="fetchBettingStrategy" />

  <div v-if="customResponse" class="game-container">
    <div class="details-container">
      <details open>
        <summary class="clickable-summary">Custom Matchup Analysis</summary>
        <div class="llm-response">
          {{ customResponse.answer }}
        </div>
      </details>
    </div>
  </div>

  <LeagueSelect class="league-select" />

  <div v-for="(oddsInfo, gameId) in store.leagueOdds" :key="gameId" class="game-container"
    :id="`game-container-${gameId}`">
    <div :id="`game-screenshot-container-${gameId}`" class="game-screenshot-container">
      <div class="row">
        <div class="matchup-info-container">
          <div class="matchup-info">
            <div class="team-info">
              <!-- Home Team Logo and Name -->
              <div class="logo-and-name">
                <img :src="`/team_logos/${store.selectedLeague}/${oddsInfo.home_team}.png`" class="team-logo"
                  @click="handleClicks(gameId)" alt="Home Team Logo"
                  @error="event => handleImageError(event, 'blue')" />
                <span>{{ oddsInfo.home_team }}</span>
              </div>
              <span class="vs">vs</span>
              <!-- Away Team Logo and Name -->
              <div class="logo-and-name">
                <img :src="`/team_logos/${store.selectedLeague}/${oddsInfo.away_team}.png`" class="team-logo"
                  @click="handleClicks(gameId)" @error="event => handleImageError(event, 'red')" alt="Away Team Logo" />
                <span>{{ oddsInfo.away_team }}</span>
              </div>
            </div>
          </div>
          <div class="game-time" @click="handleClicks(gameId)" v-html="formatGameTime(oddsInfo.game_time)"></div>
        </div>
        <div class="matchup-info">
          <div class="brand" v-show="false">
            <img src="/logo-ai.png" alt="betBOSS.ai" class="brand-logo" />
          </div>
        </div>
      </div>

      
      <!-- Odds Information -->
      <Odds :game="oddsInfo" />
      <PickConfidenceSlider v-if="llmResponses[gameId]" :game-id="gameId" />

    </div>
    <div class="buttons-container">
      <button
        :id="`analyze-button-${gameId}`"
        class="clickable-button analyze-button"
        :class="{
          'analyze-button-complete': llmResponses[gameId],
          'signup-button': !store.isLoggedIn && store.searchCount >= store.allowedSearches
        }"
        @click="handleAnalyzeButtonClick(gameId, oddsInfo.home_team, oddsInfo.away_team, oddsInfo.odds)"
        :disabled="llmResponses[gameId]"
      >
        <span v-if="llmLoading[gameId]" class="loading-indicator">
          <i class="fas fa-spinner fa-spin"></i> THINKING... <br />Up to 30 seconds.
        </span>
        <span v-else-if="llmResponses[gameId]">
          Analysis Complete
          <i class="fas fa-check"></i>
        </span>
        <span v-else-if="!store.isLoggedIn && store.searchCount >= store.allowedSearches">
          Signup for 10 more picks FREE
        </span>
        <span v-else>Analyze Pick</span>
      </button>
    </div>

    <div v-if="llmResponses[gameId]" class="details-container">
      <details open>
        <summary class="clickable-summary">Game Analysis</summary>
        <div class="llm-response">
          {{ llmResponses[gameId].answer }}
        </div>
      </details>


      <details v-if="llmResponses[gameId].sources" class="sources">
        <summary>Sources</summary>
        <ul>
          <template v-for="source in llmResponses[gameId].sources.split('\n\n')">
            <li v-if="source.trim()" :key="source">
              <div>
                <div>{{ source.split("\n")[0] }}</div>
                <a :href="source.split('\n')[1].split(': ')[1]" target="_blank">
                  {{ source.split("\n")[1].split(': ')[1] }}
                </a>
                <div>{{ source.split("\n")[2] }}</div>
              </div>
            </li>
          </template>
        </ul>
      </details>
      <details v-if="llmResponses[gameId].errors && llmResponses[gameId].errors.length > 0" class="errors">
        <summary>Errors</summary>
        <div>
          <ul>
            <li v-for="error in llmResponses[gameId].errors" :key="error">{{ error }}</li>
          </ul>
        </div>
      </details>
    </div>
  </div>
  <!-- <SuggestionBox /> -->
</template>

<script setup lang="ts">
import { ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import { useStore } from "../stores/store";
import { useMapsStore } from "../stores/maps";
import Odds from "./Odds.vue";
import LeagueSelect from "./LeagueSelect.vue";
import CustomMatchup from "./CustomMatchup.vue";
import { useGtm } from "@gtm-support/vue-gtm";
import { useToast } from "vue-toastification";
import StripeOverlay from "./StripeOverlay.vue";
import PickConfidenceSlider from './PickConfidenceSlider.vue';
import SuggestionBox from './SuggestionBox.vue';
import TokenModal from './TokenModal.vue';

const router = useRouter();
const route = useRoute();
const gtm = useGtm();
const trackEvent = gtm.trackEvent.bind(gtm);
const store = useStore();
const mapsStore = useMapsStore();

const customResponse = ref<any>(null);
const llmResponses = reactive<Record<string, any>>({});
const llmLoading = reactive<Record<string, boolean>>({});
const clickCounts = ref<Record<string, number>>({});
const tokenModal = ref();

const formatGameTime = (dateTimeStr: string): string => {
  return dateTimeStr;
};

const formatOdds = (odds: any): string => {
  return JSON.stringify(odds, null, 2);
};

const fetchBettingStrategy = async (
  matchup: string,
  oddsContext: string = "",
  gameId?: string
): Promise<void> => {
  const loadingKey = gameId || "custom";
  llmLoading[loadingKey] = true;

  try {
    const response = await axios.post(
      "https://back.betboss.ai/ask_and_markup",
      {
        search_query: matchup,
        odds_context: oddsContext,
        game_id: gameId,
        league: store.selectedLeague,
        user_tier: store.userTier || "",
        ip_address: store.userIp,
        email: store.userEmail,
      },
      {
        withCredentials: true,
      }
    );

    store.updateSearchCount(response.data.picks_used);

    const markup = JSON.parse(response.data.markup);

    if (gameId) {
      if (store.leagueOdds[gameId]) {
        store.leagueOdds[gameId].odds = markup;
      }

      llmResponses[gameId] = {
        answer: response.data.answer,
        errors: response.data.errors,
        sources: response.data.sources,
        markup: markup,
      };

      trackEvent({
        event: "game_analyze",
        noninteraction: false,
      });
    } else {
      customResponse.value = {
        answer: response.data.answer,
        markup: markup,
      };

      trackEvent({
        event: "custom_matchup_analyze",
        noninteraction: false,
      });
    }
  } catch (error: any) {
    console.error(error);
    const errorMessage =
      error.response && error.response.status === 429
        ? `Error fetching response: ${error.response.data.detail}`
        : `Error fetching response: ${error.message}`;

    if (gameId) {
      llmResponses[gameId] = { answer: errorMessage, errors: [], sources: "", markup: {} };
    } else {
      customResponse.value = { answer: errorMessage, markup: {} };
    }
  } finally {
    llmLoading[loadingKey] = false;
  }
};

const resetAndRerunAnalysis = async (gameId: string): Promise<void> => {
  try {
    const response = await axios.delete(`https://back.betboss.ai/delete-game-search/${gameId}`);
    if (response.data && response.data.message && response.data.message.includes("deleted successfully")) {
      clickAnalyzeButton(gameId);
    } else {
      console.error("Failed to delete game search:", response.data.message || "No message received");
    }
  } catch (error: any) {
    console.error("Error deleting game search:", error.response?.data?.error || error.message || error);
  }
};

const clickAnalyzeButton = (gameId: string): void => {
  const button = document.querySelector<HTMLButtonElement>(`#analyze-button-${gameId}`);
  if (button) {
    button.click();
  }
};

const handleClicks = (gameId: string): void => {
  if (!clickCounts.value[gameId]) {
    clickCounts.value[gameId] = 0;
  }
  clickCounts.value[gameId]++;

  setTimeout(() => {
    if (clickCounts.value[gameId] === 3) {
      resetAndRerunAnalysis(gameId);
    }
    clickCounts.value[gameId] = 0;
  }, 400);
};

const handleImageError = (event: Event, color: string): void => {
  const target = event.target as HTMLImageElement;
  target.src = `https://placehold.co/20x20/${color}/white`;
};

const handleAnalyzeButtonClick = (gameId: string, homeTeam: string, awayTeam: string, odds: any) => {
  if (!store.isLoggedIn && store.searchCount >= store.allowedSearches) {
    trackEvent({
      event: "signup_click_blue",
      noninteraction: false,
    });

    window.location.href = "https://auth.betboss.ai/register";
    return;
  }

  const rateLimitCheck = store.checkRateLimit();

  if (rateLimitCheck.shouldRedirect && !route.query.is_scraping) {
    if (!store.isLoggedIn) {
      window.location.href = "https://auth.betboss.ai/register";
    }
    store.showStripeOverlay = true;
    return;
  }

  fetchBettingStrategy(`${homeTeam} vs ${awayTeam}`, formatOdds(odds), gameId);
};

const openTokenModal = () => {
  tokenModal.value.openModal();
};

onMounted(async () => {
  await store.fetchUserIp();
});

</script>

<style scoped>
@import "@/assets/games.css";

.signup-button {
  background-color: #007bff;
  /* Nice blue color */
  color: white;
  border-color: #0056b3;
}

.signup-button:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.league-select {
  margin-bottom: 15px;
}

.token-button-container {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.token-button {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background: #1E1E1E;
  border: 1px solid #2c2c2c;
  color: #fff;
  padding: 0.5rem 1.25rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.token-button:hover {
  border-color: #4CAF50;
  background: #262626;
  transform: translateY(-1px);
}

.token-logo {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
</style>
