<template>
  <div class="top-bar">
    <div class="left-section">
      <div class="logo">
        <div class="logo-container">
          <a href="https://betboss.ai" style="line-height: 0;">
            <img src="../assets/logo-ai.png" alt="betBOSS.ai" />
          </a>
          <div class="actions-group">
            <div class="social-links">
              <a href="https://instagram.com/betboss.ai" target="_blank" class="social-link">
                <i class="fab fa-instagram social-icon"></i>
              </a>
              <a href="https://x.com/betboss_ai" target="_blank" class="social-link">
                <i class="social-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="#ffffff" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
                  </svg>
                </i>
              </a>
              <a href="https://t.me/betboss_ai" target="_blank" class="social-link">
                <i class="fab fa-telegram social-icon"></i>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.betboss" target="_blank" class="social-link">
                <i class="fab fa-google-play social-icon"></i>
              </a>
              <button class="token-button" @click="openTokenModal">
                <span><span class="dollar-sign">$</span>BETBOSS</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="right-section">
      <div class="right-content">
        <div class="rate-limit-info" style="display: flex; flex-direction: column; align-items: center;">
          <!-- Display remaining requests -->
          <!-- <div>50 remaining.</div> -->
          <!-- <div>{{ formatResetTime(store.reset) }} mins till reset</div> -->
          <!-- <div><strong><h4>Under Maintenance</h4></strong></div> -->
          <!-- <div v-if="!store.isLoggedIn">Signup to increase limit</div> -->
          <!-- <div v-else>{{ store.remainingSearches }} remaining.</div> -->
          <!-- Display search count only if greater than 0 and defined -->
          <div v-if="store.searchCount > -1">Picks used: {{ store.searchCount }}/<span
              :class="{ 'infinity-symbol': store.userTier === 'boss' }">{{ store.userTier === 'boss' ? '∞' :
                store.allowedSearches }}</span></div>
          <div style="text-align: center;">
            <div class="user-email">{{ store.userEmail }}</div>
            <a v-if="store.isLoggedIn && store.userTier === 'free'" href="https://betboss.ai/stripe"
              style="display: inline-block; margin: 0 auto;" @click="handlePricingClick">FREE TRIAL</a>
            <template v-if="!store.isLoggedIn">
              <a href="https://back.betboss.ai/protected" style="display: inline-block; margin: 0 auto;"
                @click="handleLoginClick">LOGIN</a> |
              <a href="https://auth.betboss.ai/register" :class="{ 'bigger-signup': showProminentSignup }"
                @click="handleSignupClick">SIGNUP</a>
            </template>

            <div v-if="store.userTier === 'rookie'">Rookie</div>
            <div v-if="store.userTier === 'pro'">Pro</div>
            <div v-if="store.userTier === 'elite'">Elite</div>
            <div v-if="store.userTier === 'boss'">Boss</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TokenModal ref="tokenModal" />
  <WhysThisGood :is-open="isWhyModalOpen" @close="closeWhyModal" />
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch, nextTick } from "vue";
import { useStore } from "../stores/store";
import { useRouter, useRoute } from 'vue-router';
import { useGtm } from "@gtm-support/vue-gtm";
import axios from 'axios';
import WhysThisGood from './WhysThisGood.vue';
import TokenModal from './TokenModal.vue';

const store = useStore();
const router = useRouter();
const route = useRoute();
const isScraping = computed(() => route.query.scraping === 'true');

const gtm = useGtm(); // Initialize GTM
const trackEvent = gtm.trackEvent.bind(gtm); // Bind trackEvent

const hydrateNow = () => {
  console.log('Hydrating');
  store.$hydrate();
}

onMounted(async () => {
  store.$hydrate();
  await store.checkLoginStatus();

  const urlParams = new URLSearchParams(window.location.search);

  window.onload = () => {
    nextTick(() => {
      if (store.isLoggedIn) {
        console.log('User is logged in, setting GTM properties');
        window.dataLayer = window.dataLayer || [];
        console.log('Pushing user_id to dataLayer:', store.userId);
        window.dataLayer.push({
          'user_id': store.userId || undefined,
        });
        console.log('Pushing user info to dataLayer:', {
          user_tier: store.userTier || 'free',
          user_id: store.userId || '',
          user_email: store.userEmail ? store.userEmail.replace('@', '-') : undefined,
        });
        window.dataLayer.push({
          'user_tier': store.userTier || 'free',
          'user_id': store.userId || '',
          'user_email': store.userEmail ? store.userEmail.replace('@', '-') : undefined,
          'event': 'user_info'
        });
      }
    });
  };

  // Additional tracking for fresh login
  if (urlParams.get('action') === 'logged_in') {
    console.log('User just logged in');
    trackEvent({
      event: "login",
      noninteraction: false,
      user_tier: store.userTier || 'free',
      user_id: store.userId || '',
      user_email: store.userEmail || '',
    });
  }

  // Check if the URL contains the query parameter 'today=true'
  if (urlParams.get('today') === 'true') {
    console.log('Today is true!');
  }
});

// utils.js or within your Vue component script
function getCookie(name: string): string | null {
  let cookieValue: string | null = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.startsWith(name + "=")) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1)).replace(/\"/g, "");
        break;
      }
    }
  }
  return cookieValue;
}

function handleSignupClick() {
  trackEvent({
    event: "signup_click_top",
    noninteraction: false,
  });
}

function handlePricingClick() {
  trackEvent({
    event: "freetrial_click",
    noninteraction: false,
  });
}

function handleLoginClick() {
  trackEvent({
    event: "login_click",
    noninteraction: false,
  });
}

function handleHowItWorksClick() {
  trackEvent({
    event: "how_it_works_click",
    noninteraction: false,
  });
}

const isWhyModalOpen = ref(false);

const openWhyModal = () => {
  isWhyModalOpen.value = true;
};

const closeWhyModal = () => {
  isWhyModalOpen.value = false;
};

const tokenModal = ref(null);

const openTokenModal = () => {
  tokenModal.value?.openModal();
};

// Add computed property for showing prominent signup
const showProminentSignup = computed(() => {
  return !store.isLoggedIn && store.searchCount >= store.allowedSearches;
});
</script>

<style scoped>
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
  margin-bottom: 5px;
}

.left-section {
  display: flex;
  flex-direction: column;
}

.right-section {
  display: flex;
  align-items: flex-start;
}

.right-content {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}

.actions-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-left: 8px;
}

.social-links {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.token-button {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background: #1E1E1E;
  border: 1.5px solid #4e9f3d;
  color: white;
  padding: 10px 8px;
  margin-right: 5px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.85rem;
  font-weight: 500;
  height: 20px;
}

.dollar-sign {
  color: #4e9f3d;
  font-weight: bold;
}

.token-button:hover {
  background: #2c2c2c;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 0.5rem; */
}

.logo img {
  width: 175px;
  height: auto;
  margin-bottom: 4px;
}

.logo a {
  color: #4e9f3d;
  font-size: 1.5em;
  letter-spacing: 1px;
  margin-bottom: 3px;
}

.slogan {
  display: block;
  color: white;
  font-size: 14px;
  letter-spacing: 1px;
  margin-left: 8px;
  line-height: 1;
  margin-bottom: 8px;
}

.rate-limit-info {
  color: #fff;
  background-color: #333;
  padding: 2px;
  border-radius: 8px;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: left;
  /* Align text to the left */
  display: flex;
  /* Use flexbox to align items */
  flex-direction: column;
  /* Stack items vertically */
  align-items: flex-start;
  padding-left: 15px;
  padding-right: 15px;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  /* Align items to the start (left) of the container */
}

.social-icon {
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  height: 16px;
}

.social-icon svg {
  height: 16px;
  width: 16px;
}

a:hover .social-icon {
  color: #4e9f3d;
  transition: color 0.3s ease;
}

a:hover .social-icon svg path {
  fill: #4e9f3d;
  transition: fill 0.3s ease;
}

.login-link {
  font-size: 18px;
  cursor: pointer;
  color: #4e9f3d;
  text-decoration: none;
  margin-right: 20px;
  font-family: "Franklin Gothic Medium", "Arial Narro w", Arial, sans-serif;
  letter-spacing: 1px;
}

.login-link:hover {
  text-decoration: underline;
  color: #4e9f3d;
}

.bigger-signup {
  /* font-size: 18px; */
  font-weight: bold;
}

.infinity-symbol {
  font-size: 1.2em;
  font-family: 'Arial Unicode MS', 'DejaVu Sans', 'Segoe UI Symbol';
  margin-left: 2px;
}

.user-email {
  font-size: 14px;
  color: white;
  margin-bottom: 5px;
}

.social-link {
  color: white;
  padding: 2px;
}
</style>
