<template>
  <div v-if="isOpen" class="token-modal-overlay" @click="closeModal">
    <div class="token-modal-content" @click.stop>
      <button class="token-modal-close" @click="closeModal">
        <i class="fas fa-times"></i>
      </button>
      
      <div class="token-modal-info">
        <h2>$BETBOSS Token</h2>
        <h3>The Best Sport Predictions Mathematically Possible</h3>
        <h1>Coming Soon!</h1>

        <div class="token-modal-benefits">
          <h3>Profit Sharing</h3>
          <ul>
            <li>💰 50% of Betboss profits distributed to token owners</li>
            <li>📈 Rewards based on token holding percentage</li>
            <li>⏱️ Weekly profit distribution</li>
            <li>🔒 Smart contract automated distributions</li>
            <li>⚡ Real-time profit reporting via Stripe integration</li>
            <li>📊 Up-to-the-minute earnings transparency</li>
          </ul>
        </div>


        <div class="token-modal-benefits">
          <h3>Advanced Prediction Technology</h3>
          <ul>
            <li>🧮 Access to proprietary mathematical models</li>
            <li>🤖 State-of-the-art machine learning algorithms</li>
            <li>📊 Real-time statistical analysis engine</li>
            <li>🎯 Advanced probability calculations</li>
            <li>💡 Multi-factor prediction modeling</li>
          </ul>
        </div>


        <div class="token-modal-allocation">
          <h3>Technology Investment</h3>
          <p>Your investment drives innovation in:</p>
          <ul>
            <li>Advanced machine learning model development</li>
            <li>High-frequency data processing systems</li>
            <li>Statistical analysis infrastructure</li>
            <li>Real-time prediction engines</li>
          </ul>
        </div>

        <button class="token-modal-buy" @click="buyTokens">Invest in $BETBOSS</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const isOpen = ref(false);

const openModal = () => {
  isOpen.value = true;
};

const closeModal = () => {
  isOpen.value = false;
};

const buyTokens = () => {
  // Implement token purchase logic
  window.open('https://yourexchange.com/betboss', '_blank');
};

defineExpose({
  openModal,
  closeModal
});
</script>

<style scoped>
.token-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  padding: 20px;
}

.token-modal-content {
  background-color: #1a1a1a;
  border-radius: 12px;
  position: relative;
  max-width: 500px;
  width: 90%;
  margin: 2rem auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* padding: 2.5rem; */
  color: #ffffff;
  border: 1px solid #2c2c2c;
  max-height: 90vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #2c2c2c #0f0f0f;
}

.token-modal-content::-webkit-scrollbar {
  width: 8px;
}

.token-modal-content::-webkit-scrollbar-track {
  background: #0f0f0f;
  border-radius: 4px;
}

.token-modal-content::-webkit-scrollbar-thumb {
  background: #2c2c2c;
  border-radius: 4px;
}

.token-modal-content::-webkit-scrollbar-thumb:hover {
  background: #3c3c3c;
}

.token-modal-close {
  position: absolute;
  right: 35px;
  top: 25px;
  background: none;
  border: 2px solid #ffffff;
  font-size: 20px;
  cursor: pointer;
  color: #ffffff;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s;
  opacity: 0.5;
  
  
}

.token-modal-close:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.token-modal-close i {
  line-height: 1;
}

.token-modal-info {
  padding: 20px 30px;
  color: #fff;
  max-width: 600px;
  margin: 0 auto;
}

.token-modal-info h2 {
  font-size: 2em;
  margin: 0 0 10px 0;
  text-align: center;
}

.token-modal-info h3 {
  font-size: 1.2em;
  margin: 0 0 30px 0;
  text-align: center;
  color: #a0aec0;
}

.token-modal-logo {
  margin: 1rem auto;
  width: 80px;
  height: 80px;
}

.token-modal-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

h2 {
  color: #fff;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

h3 {
  color: #fff;
  margin-top: 1.5rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.token-modal-benefits, 
.token-modal-allocation {
  text-align: left;
  margin: 1.5rem 0;
  background: #1a1a1a;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid #2c2c2c;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin: 0.75rem 0;
  color: #ccc;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

li::before {
  content: "•";
  color: #666;
  margin-right: 0.5rem;
}

.token-modal-info h1 {
  text-align: center;
  margin: 20px 0;
  color: #4e9f3d;
  font-size: 1.8em;
}

.token-modal-buy {
  display: block;
  margin: 30px auto;
  padding: 12px 30px;
  background-color: #4e9f3d;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.2s;
}

.token-modal-buy:hover {
  background-color: #3d7f31;
}
</style>
